.collection-page {
    display: flex;
    flex-direction: column;
}
.collection-page .title {
    font-size: 38px;
    margin: 0 auto 30px;
}
.collection-page .items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}
.collection-page .items .collection-item {
    margin-bottom: 30px;
}
