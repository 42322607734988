.textLabel{
    color:#727272;
    /* font-size: smaller; */
    font-weight: 500;
}
.upperBlock{
    background-color: #f2ffec;
    padding: 20px;
}
.lowerBlock{
    background-color: #f2ffec;
    padding: 20px;
    margin-top:1px;
}
.divImg {

}

.textLable {
    text-align: left;
    font-size: 13px;
    color: #61706b;
}
.headingLabel {
    text-align: left;
font-size: 15px;
color: #5a6260;
font-weight: 500;
}