
/* Quick and dirty normalize hax */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .outer {
    width: 95%;
    display: inline-block;
    text-align: center;
  }
  .container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .heading {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
  }
  
  .searchInputWrapper {
    position: relative;
    margin-bottom: 5px
  }
  
  .searchInput {
     width: 100%; 
    height: 2rem;
    padding: 0 1rem; 
    border-radius: 2rem;
    border: none;
  
    /* transition: transform 0.1s ease-in-out; */
  }
  @media (max-width: 992px) {
    .searchInput {
      /* width: 50rem; */
      /* width: fit-content; */
      height: 2rem;
      
      /* transition: transform 0.1s ease-in-out; */
    }
  }
  
  ::placeholder {
    color: #a1a1a1;
  }
  
  /* hide the placeholder text on focus */
  :focus::placeholder {
    text-indent: -999px
  }
  
  .searchInput:focus {
    outline: none;
    /* transform: scale(1.1);
    transition: all 0.1s ease-in-out; */
  }
  
  .searchInputIcon {
    position: absolute;
    right: 0.8rem;
    top: 0.5rem;
    color: #a1a1a1;
    transition: all 0.1s ease-in-out;
  }
  
  .container:focus-within > 
  .searchInputWrapper > .searchInputIcon {
    right: 0.2rem;
  }