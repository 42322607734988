.checkout-page {
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.checkout-page .checkout-header {
    width: 100%;
    /* //padding: 10px 0; */
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey;
}
.checkout-page .checkout-header .header-block {
    text-transform: capitalize;
    width: 23%;
}
.checkout-page .checkout-header .header-block:last-child {
    width: 8%;
}
.checkout-page .total {
    margin-top: 30px;
    margin-left: auto;
    font-size: 36px;
}
.checkout-page button {
    margin-left: auto;
    margin-top: 50px;
}
.checkout-page .test-warning {
    margin-top: 20px;
    color: red;
}
